import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"

import donate from "../images/Spread the word.svg"
import fail from "../images/notfound.png"
import pending from "../images/dnicon2.png"
import { Row, Container } from "react-bootstrap"

const DonationStatus = ({ location }) => {
  const { search } = location
  let component = null
  if (!search) component = <Pending />
  else {
    const params = JSON.parse(atob(search.replace("?", "")))
    switch (params.status) {
      case "Success": {
        component = <SuccessPage />
        break
      }
      case "Aborted":
      case "Failure":
      case "Invalid": {
        component = <FailurePage />
      }
    }
  }
  return (
    <Layout>
      <SEO title="Donation Status" />
      <div className="py-5">
        <Container>
          <div className="d-flex justify-content-center align-items-center flex-column">
            {component}
          </div>
        </Container>
      </div>
    </Layout>
  )
}
DonationStatus.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default DonationStatus

// use ?eyJuYW1lIjoiTW9jayBQZXJzb24iLCJzdGF0dXMiOiJTdWNjZXNzIn0= to test
function SuccessPage() {
  return (
    <Row>
      <div className="col-xs-12 col-lg-4 offset-lg-1">
        <img src={donate} className="w-75 mb-5 mx-auto d-block" />
      </div>
      <div className="col-lg-6 d-flex justify-content-start align-items-center">
        <div>
          <h1 className="jtf-head">Thank You</h1>
          <p>
            We would like to express our sincere gratitude to you for your
            donation towards Teach For India.
          </p>
          <p>
            Your contribution makes it possible for us to continue placing
            outstanding leaders in schools and communities where they can be an
            influential force for change, to ensure education opportunity for
            all children. We are very grateful for your partnership and your
            commitment to our mission.
          </p>
          <p>Please accept our deepest thanks.</p>
          <p>
            We will send you a receipt of your donation once the payment has
            been verified. Feel free to write to us at{" "}
            <a href="mailto:donate@teachforindia.org">
              donate@teachforindia.org
            </a>{" "}
            for any questions or suggestions.
          </p>
        </div>
      </div>
    </Row>
  )
}

// ?eyJuYW1lIjoiTW9jayBQZXJzb24iLCJzdGF0dXMiOiJGYWlsdXJlIn0=
function FailurePage() {
  return (
    <Row>
      <div className="col-lg-4 offset-lg-1">
        <img src={fail} className="w-75 mb-5 mx-auto d-block" />
      </div>
      <div className="col-lg-6 d-flex align-items-start justify-content-center">
        <div>
          <h1 className="jtf-head">Transaction Cancelled</h1>
          <p>
            It seems that you cancelled the transaction before completing your
            donation.
          </p>
          <p>
            {" "}
            Please write to us at{" "}
            <a href="mailto:donate@teachforindia.org">
              donate@teachforindia.org
            </a>{" "}
            if you are facing any issues or have any questions.
          </p>
        </div>
      </div>
    </Row>
  )
}
function Pending() {
  return (
    <Row>
      <div className="col-lg-4 offset-lg-1">
        <img src={pending} className="w-50 mb-5 mx-auto d-block" />
      </div>
      <div className="col-lg-6 d-flex align-items-start justify-content-center">
        <div>
          <h1 className="jtf-head">Updating Status</h1>
          <p>Please wait while we fetch details of your donation</p>
          <p>
            {" "}
            Please write to us at{" "}
            <a href="mailto:donate@teachforindia.org">
              donate@teachforindia.org
            </a>{" "}
            if you are facing any issues or have any questions.
          </p>
        </div>
      </div>
    </Row>
  )
}
